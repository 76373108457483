import React from 'react';
import Task from './pages/task';



import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    
    <Router>
        <Routes>
          <Route path="/" element={<Task />} />
        </Routes>
    </Router>
  );
}

export default App;
