import React, { useState, useEffect } from 'react';
import '../App.css'; // 确保引入了Tailwind CSS 和 daisyUI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [token, setToken] = useState('');
  const [uid, setUid] = useState('');
  const [taskId, setTaskId] = useState('4');
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { uid, token, task_id: taskId };

    try {
      const response = await fetch('https://v1.vup.name/api/task_join', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      setMessage(result.msg);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error:', error);
      setMessage('请求失败');
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    localStorage.setItem('token', token);
  }, [token]);

  return (
    <div className="App flex justify-center items-center h-screen bg-gray-900">
      <form onSubmit={handleSubmit} className="bg-gray-800 p-8 rounded-lg shadow-xl max-w-md w-full space-y-4 text-left">
        <h2 className="text-xl font-semibold text-white text-center">任务报名</h2>
        <div>
          <label className="block text-sm text-white font-bold mb-1">报名主播UID</label>
          <input value={uid} onChange={e => setUid(e.target.value)} required className="input font-bold input-bordered input-primary w-full" />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-bold">STAFF密钥:</label>
          <div className="input input-bordered input-primary flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" /></svg>
            <input type="password" className="grow" value={token} onChange={e => setToken(e.target.value)} required />
            <span className="text-sm opacity-70">请输入您的密钥</span>
          </div>
        </div>
        <div>
          <label className="block text-sm text-white font-bold mb-1">选择任务</label>
          <select value={taskId} onChange={e => setTaskId(e.target.value)} required className="select font-bold select-bordered select-primary w-full">
            <option value="4">虚拟公会招新任务</option>
            <option value="5">米家游戏UP招新任务</option>
          </select>
        </div>
        <button type="submit" className="btn w-full">提交</button>
      </form>

      {isModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">提醒</h3>
            <p className="py-4 font-bold">{message}</p>
            <div className="modal-action">
              <button className="btn btn-error text-white" onClick={() => setIsModalOpen(false)}><FontAwesomeIcon icon={faTimes} />关闭</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
